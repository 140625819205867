<template>
  <div>

    <div
      class="baseCard"
      style="margin-top: 20px;display: flex;flex-direction: column;"
    >
      <p style="
font-size: 20px;
font-weight: bold;
color: #555454;
line-height: 48px;text-align:center;margin-top:20px">{{detailData.title}}</p>
      <TitleLine width="400px"></TitleLine>
      <div class="introInfo">
        <span>录入时间：{{detailData.publishDate}}</span>
        <span>浏览次数：{{detailData.views}}次</span>
      </div>
      <div
        class="detailContent"
        v-html="detailData.text || detailData.units_info"
      ></div>
       <el-button class="submitBtn" v-if="isDonate" @click="donateJump">我要捐赠</el-button>
	   <!--献花点烛-->
	   <!-- <flower-candle  v-if="isRemembrance&&isRemembrance!='false'"></flower-candle> -->
    </div>
  </div>
</template>

<script>
import TitleMore from "@components/TitleMore";
import TitleLine from "@components/TitleLine";
import apiUrls from "@api";
import FlowerCandle from "../Bodydonate/brance/flowercandle.vue";

export default {
  data() {
    return {
      detailData: {},
      isDonate: false,
	  isRemembrance: false,
    };
  },

  components: {
    TitleMore,
    TitleLine,
	FlowerCandle
  },

  mounted() {
    this.getDetail(this.$route.query.id);
  	this.isRemembrance = this.$route.query.isremembrance;
    // console.log(this.$route.query.isDonate)
    this.isDonate = this.$route.query.isDonate;
	// console.log(this.isDonate)
  },

  methods: {
    getDetail(id) {
      apiUrls.selfDynamicDetail({ id }).then((res) => {
        this.detailData = res.results.data;
      });
    },
    donateJump() {
      this.$router.push({path:'idonate',query:{projectId:this.$route.query.id}})
    }
  },
};
</script>
<style>
	.baseCard p img{
		display: block;
		margin: 0 auto!important;
	}
</style>
<style lang="less" scoped>
.introInfo {
  width: 600px;
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  span {
    font-size: 14px;
    color: #999999;
  }
}
.detailContent {
  margin-top: 20px;
  padding: 20px 40px;
  line-height: 30px;
}
.submitBtn {
  width: 150px;
  margin:0 auto;
}
</style>